import React from "react";
import ReactDOM from "react-dom";
import {HashRouter, Switch, Route, Redirect} from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
// Stylesheet
import "./index.scss";
// Scenes
import Home from "./scenes/Home";
import FR from "./scenes/FR1";
import FR2 from "./scenes/FR2";
// Components
import Error404 from "./components/common/Error404";
import RedirectAs404 from "./components/common/RedirectAs404";
import Imprint from "./scenes/Imprint";

const Root = () => {
    return (
        <div>
            <Switch>
                <Route
                    exact
                    path="/"
                    render={() => {
                        return (
                            <Redirect to="/og" />
                        )
                    }}
                />
                <Route path="/og" component={Home} />
                <Route path="/fr" component={FR} />
                <Route path="/fr2" component={FR2} />
                <Route path="/imprint" component={Imprint} />
                <Route component={RedirectAs404} />
            </Switch>
        </div>
    );
};

ReactDOM.render(
    <HashRouter>
        <div>
            <Route render={({ location }) => (location.state && location.state.is404 ? <Error404 /> : <Root />)} />
        </div>
    </HashRouter>,
    document.getElementById("root")
);
serviceWorker.unregister();
