import React, { useEffect } from "react";
import Particles from "react-particles";
import { Container } from "react-grid-system";
// Sections
import Header from "./../Home/Header";
// Components
import Helmet from "../../components/common/Helmet";

function FR() {

    useEffect(() => {
        document.documentElement.className = "home-1 skin-7";
        return () => {
            document.documentElement.className = "";
        };
    });

    return (
        <div>
            <Helmet title="FR" />
            <Header />
            <section className="section section-hero section-hero-2 overlay-image"
                     style={{backgroundImage: `url(/assets/images/hero/hero_fr.png)`}}>
                <span className="el-circle-side right"></span>
                <Particles
                    params={{
                        particles: {
                            number: {
                                value: 300,
                                density: {
                                    enable: true,
                                },
                            },
                            size: {
                                value: 3,
                                random: true,
                            },
                            move: {
                                direction: "top",
                                out_mode: "out",
                            },
                            line_linked: {
                                enable: false,
                            },
                        },
                        interactivity: {
                            events: {
                                onhover: {
                                    enable: true,
                                    mode: "bubble",
                                },
                                onclick: {
                                    enable: true,
                                    mode: "repulse",
                                },
                            },
                            modes: {
                                bubble: {
                                    distance: 250,
                                    duration: 2,
                                    size: 3,
                                },
                                repulse: {
                                    distance: 400,
                                    duration: 4,
                                },
                            },
                        },
                    }}
                />
                <div className="display-center">
                    <Container>
                        <div className="el-heading">
                            <p className="el-icon">
                                <span className="el-icon-title">SYNDIKAT Immobilien</span>
                            </p>
                            <h1>
                                Smarte
                                <br/>
                                Projektentwicklung
                            </h1>
                            <button className="button button-lg button-dark">
                                <span className="text">Kontakt</span>
                            </button>
                        </div>
                    </Container>
                </div>
            </section>
        </div>
    );
}

export default FR;
