import React, {useEffect} from "react";
import { Container, Hidden, Row, Col } from "react-grid-system";
import Header from "../Home/Header";

function Imprint() {
    useEffect(() => {
        document.documentElement.className = "home-1 skin-7";
        return () => {
            document.documentElement.className = "";
        };
    });
    return (
        <div>
        <Header />
        <section className="section section-about section-about-1">
            <div className="display-spacing">
                <Container>
                    <Row>
                        <Hidden xs sm md>
                            <Col lg={5}>
                                <div className="about-image">
                                    <div className="image overlay-image" style={{ backgroundImage: `url(/assets/images/about/1-1.jpg)` }}></div>
                                </div>
                            </Col>
                        </Hidden>
                        <Col lg={7}>
                            <div className="about-content">
                                <header className="el-heading">
                                    <h2 className="el-icon">
                                        <span className="el-icon-title">§</span>
                                    </h2>
                                    <h3>
                                        <span>Impressum</span>
                                    </h3>
                                    <div className="divider divider-1"></div>
                                    <p>Angaben gemäß § 5 TMG:</p>
                                </header>
                                <Row className="pt-30">
                                    <Col xs={12} sm={12} md={6}>
                                        <div className="el-card-icon">
                                            <div className="el-icon">
                                                <span className="el-icon-icon ti-ruler-pencil"></span>
                                            </div>
                                            <div className="content">
                                                <h5>B+F Immobilien</h5>
                                                <p>
                                                    Frankenweg 17<br/>
                                                    77767 Appenweier</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={6}>
                                        <div className="el-card-icon">
                                            <div className="el-icon">
                                                <span className="el-icon-icon ti-image"></span>
                                            </div>
                                            <div className="content">
                                                <h5>Vertreten durch:</h5>
                                                <p>Herr Mario Beiser<br/>
                                                    Herr Timo Fink</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={6}>
                                        <div className="el-card-icon">
                                            <div className="el-icon">
                                                <span className="el-icon-icon ti-brush-alt"></span>
                                            </div>
                                            <div className="content">
                                                <h5>Inhaltlich verantwortlich im Sinne des § 18 Abs. 2 MStV:</h5>
                                                <p>Herr Mario Beiser<br/>
                                                    Herr Timo Fink</p>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col xs={12} sm={12} md={6}>
                                        <div className="el-card-icon">
                                            <div className="el-icon">
                                                <span className="el-icon-icon ti-ruler-pencil"></span>
                                            </div>
                                            <div className="content">
                                                <h5>Kontakt</h5>
                                                <p>
                                                    Telefon: +49 (0) 781 12 78 65 14<br/>
                                                    E-Mail: <a href="mailto:hello@syndikat.immo">hello@syndikat.immo</a></p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
        </div>
    );
}

export default Imprint;
